import React from 'react'
//import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

export const Footer = ({ bgColor }) => {
    return (
        <section>
            <div className={`w-full ${bgColor ? bgColor : 'bg-primary'} lg:p-16 p-8 grid grid-cols-12 lg:gap-16 gap-8 text-white`}>
                <div className='lg:col-span-5 col-span-12 flex flex-col lg:gap-6 gap-4'>
                <h1 className='font-bold uppercase text-xl'>aurum campus academy</h1>
                    <p>Sesuai dengan arti nama AURUM yaitu emas, AURUM hadir untuk menjadikan generasi emas Indonesia melangkah menuju Indonesia Emas 2045</p>
                </div>
                <div className='lg:col-span-3 flex flex-col lg:gap-6 gap-4'>
                    <h1 className='font-bold uppercase text-xl'>paket</h1>
                    <div className='flex lg:flex-col lg:gap-0 gap-8'>
                        <p>Paket Try Out UTBK</p>
                        <p>Paket Try Out Poltekkes</p>
                        <p>Paket Try Out Kedinasan</p>
                    </div>
                </div>
                
                <div className='lg:col-span-2 col-span-12 flex flex-col lg:gap-6 gap-4'>
                    <h1 className='font-bold uppercase text-xl'>kontak</h1>
                    <div className='flex flex-col'>
                        <table>
                            <td>
                                <a href="https://api.whatsapp.com/send?phone=6282318381228">
                                <img src='/assets/img/wa.svg' className="w-26 h-12" /> </a>
                            </td>
                            <td>
                                <a href="https://www.instagram.com/aurumcampusacademy/">
                                <img src='/assets/img/ig.svg' className="w-26 h-12" /> </a>
                            </td>
                            <td>
                                <a href="https://www.tiktok.com/@aurumcampusacadem?lang=en">
                                <img src='/assets/img/tiktok.svg' className="w-26 h-12" /> </a>
                            </td>
                        </table>                      
                    </div>
                </div>
            </div>
        </section>
    )
}
